(function () {
    var module;

    module = angular.module('neoValidation', []);

    module.directive('neoDecimalOnly', ['$filter', '$parse', function ($filter, $parse) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                element.attr("style", "text-align:right;");
                //element.attr("data-inputmask-regex", "^[0-9]+([,][0-9]{1,2})?$");
                //element.inputmask("Regex");

                var decimals = $parse(attrs.decimals)(scope);
                var allowNegative = attrs.allowNegative !== undefined && attrs.allowNegative !== null ? attrs.allowNegative : false;

                ngModelController.$parsers.push(function (data, e) {
                    // Attempt to convert user input into a numeric type to store
                    // as the model value (otherwise it will be stored as a string)
                    // NOTE: Return undefined to indicate that a parse error has occurred
                    //       (i.e. bad user input) 
                    var fixedDecimal = parseFloat(Math.round(data.replace(",", ".") * 100) / 100).toFixed(2);
                    var parsed = parseFloat(fixedDecimal);
                    return !isNaN(parsed) ? parsed : 0;
                });

                ngModelController.$formatters.push(function (data) {
                    //convert data from model format to view format 
                    return $filter('number')(data, decimals); //converted
                });


                element.bind('focus', function () {
                    if (ngModelController.$modelValue) {
                        var modelVal = ngModelController.$modelValue;
                        element.val((modelVal + "").replace(".", ","));
                    }
                    if (element.val() === "0") {
                        element.select();
                        element.bind('mouseup', function (e) {
                            e.preventDefault();
                        });
                    }
                });

                element.bind('blur', function () {
                    // Apply formatting on the stored model value for display
                    var formatted = $filter('number')(ngModelController.$modelValue, decimals);
                    element.val(formatted);
                });

                element.bind('keypress', function (data) {
                    var item = data.srcElement;
                    var unicode = data.keyCode ? data.keyCode : data.charCode;

                    var val = ngModelController.$viewValue;
                    var cursorPos = element[0].selectionStart;

                    if (val.indexOf(',') >= 0 && unicode == 44) {
                        data.preventDefault();
                        return false;
                    }
                    if (allowNegative === true || allowNegative === "true") {
                        if (unicode == 45 && cursorPos > 0) {
                            data.preventDefault();
                            return false; //disable key press
                        }
                        if (unicode != 8 && unicode != 44 && unicode !== 45) { //if the key isn't the backspace key (which we should allow)
                            if (unicode < 48 || unicode > 57) { //if not a number 
                                data.preventDefault();
                                return false //disable key press
                            }
                        }
                    } else {
                        if (unicode != 8 && unicode != 44) { //if the key isn't the backspace key (which we should allow)
                            if (unicode < 48 || unicode > 57) { //if not a number 
                                data.preventDefault();
                                return false //disable key press
                            }
                        }
                    }
                });
            }
        }
    }]);

    module.directive('neoDateOnly', function () {
        return {
            restrict: "EA",
            link: function (scope, element, attrs) {
                var id = attrs.vsDateId;

                element.attr("id", id);
                element.attr("data-inputmask", "'alias': 'dd.mm.yyyy'");

                $("#" + id).inputmask();
                VSJSTools.AttachNewDatePicker("#" + id);
            }
        };
    });

    module.directive('neoIntOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {

                element.attr("data-inputmask-regex", "^[0-9]*$");
                element.inputmask("Regex");
                //scope.$watch('ngModel', function (newValue, oldValue) {
                //    console.debug("1: ", newValue, oldValue);
                //    newValue = (newValue + "").replace(".", "");
                //    scope.ngModel = (scope.ngModel + "").replace(".", "");
                //    var arr = String(newValue).split("");
                //    console.debug("length: ", arr.length);
                //    //if (arr.length === 0) {
                //    //    scope.ngModel = "";
                //    //    element.val("");
                //    //};
                //    function isInt(value) {
                //        if ((undefined === value) || (null === value)) {
                //            return false;
                //        }
                //        return value % 1 == 0;
                //    }

                //    if (isInt(newValue) === false) {
                //        console.debug("2: ", newValue, oldValue);
                //        scope.ngModel = parseInt(oldValue ? oldValue : "");
                //    }
                //});
            }
        };
    });

    module.directive('neoBooleanOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {
                if (attrs.vsBooleanType === "bit") {
                    element.append('<option value="False">Hayır</option>');
                    element.append('<option value="True">Evet</option>');
                } else {
                    element.append('<option value="0">Hayır</option>');
                    element.append('<option value="1">Evet</option>');
                }
            }
        };
    });

    module.directive('neoAlphaNumeric', function ($parse) {
        return {
            restrict: 'EA',
            require: 'ngModel',
            link: function (scope, iElement, iAttrs, controller) {
                var restrict = "[^a-z0-9\-\s]";
                scope.$watch(iAttrs.ngModel, function (value) {
                    if (!value) {
                        return;
                    }
                    $parse(iAttrs.ngModel).assign(scope, value.toLowerCase().replace(new RegExp(restrict, 'g'), '').replace(/\s+/g, '-'));
                });
            }
        }
    });

    module.directive('neoPhoneOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {
                element.attr("data-inputmask", "'mask' : '09999999999'");
                element.inputmask();
            }
        };
    });

    module.directive('neoEmailOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {
                element.attr("data-inputmask", "'alias' : 'email'");
                element.inputmask();
            }
        };
    });

    module.directive('neoRegexOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {
                if (attrs.vsRegex !== undefined) {
                    element.attr("data-inputmask-regex", "" + attrs.vsRegex);
                    element.inputmask("Regex");
                }
            }
        };
    });

    module.directive('neoTextOnly', function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '='
            },
            link: function (scope, element, attrs) {
                element.attr("data-inputmask-regex", "^([a-zışüğçöA-ZİŞÜĞÇÖ]+ )*[a-zışüğçöA-ZİŞÜĞÇÖ]+$");
                element.inputmask("Regex");
            }
        };
    });

    module.directive('neoSafeForJson', ['$filter', '$parse', '$timeout', function ($filter, $parse, $timeout) {
        return { 
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                element.attr("onkeypress", "return Validator.SafeForJSONHandler(this,event);");
            }
        };
    }]);
    /*
    module.directive('intOnlyInput', function () {
        return {
            restrict: 'EA',
            template: '<input class="form-control" ng-model="vsModel" />',
            scope: {
                vsModel: '='
            },
            link: function (scope, element, attrs) {
                scope.$watch('vsModel', function (newValue, oldValue) {
                    //console.debug(newValue);
                    newValue = (newValue + "").replace(".", "");
                    scope.vsModel = (scope.vsModel + "").replace(".", "");
                    var arr = String(newValue).split("");
                    if (arr.length === 0) return;
                    function isInt(value) {
                        if ((undefined === value) || (null === value)) {
                            return false;
                        }
                        return value % 1 == 0;
                    }

                    if (isInt(newValue) === false) {
                        scope.vsModel = parseInt(oldValue);
                    }
                });
            }
        };
    });

    module.directive('dateOnlyInput', function () {
        return {
            restrict: 'EA',
            template: '<input class="form-control" ng-model="vsModel" />',
            scope: {
                vsModel: '=',
                inputId: '='
            },
            link: function (scope, element, attrs) {
                element.find('input').attr("readonly", true);
                element.find('input').attr("id", scope.inputId);
                VSJSTools.AttachNewDatePicker("#" + scope.inputId);
            }
        };
    });
    */

    //module.directive('vsNegativeDecimalOnly', ['$filter', '$parse', function ($filter, $parse) {
    //    return {
    //        require: 'ngModel',
    //        link: function (scope, element, attrs, ngModelController) {
    //            //element.attr("style", "text-align:right;");
    //            element.attr("data-inputmask-regex", "^-?[0-9]{1,12}(?:\.[0-9]{1,4})?$");
    //            element.inputmask("Regex");

    //        }
    //    }
    //}]);

    module.directive('neoIbanOnly', ['$filter', '$parse', function ($filter, $parse) {
        return {
            
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                element.attr("data-inputmask-regex", "^[a-zA-Z]{2}[0-9]{24}?$");
                element.inputmask("Regex");

            }
        }

    }]);


}).call(this);