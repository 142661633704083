
app.directive('rewardPointReplacer', ["$translate", function ($translate) {
    return {
        restrict: 'EA',
        template: '<span>{{editedReward}}</span>',
        scope: {
            reward: '=ngModel'
        },
        controller: function ($scope) {
            
            function update() {
                $scope.editedReward = $scope.reward.replace("label.point", $translate.instant("label.point"));
               
            }
            
            $scope.$watch('reward', function (newValue, oldValue) {
                if (newValue) {
                    update();
                }
            });
            if($scope.reward)
                update();
        }
    };
}]);