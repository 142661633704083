app.controller("registerCtrl", ['$scope', '$sce', '$http', '$location','Notification','$translate',
    function($scope, $sce, $http, $location,Notification,$translate) {
        $scope.dataLoading = false;
        $scope.isExternal = false;
        $scope.termsChecked = false;
        $scope.termsChecked1 = false;
        $scope.userData = {
        	firstName: '',
            surname: '',
            email: ''
        };
        $scope.extData = {
            company: '',
            idno: 0,
            education: 3,
            job: '',
            phone: ''
        };
        $scope.isCollapsed=false;
        $scope.checkExternal = function () {
          if( $scope.userData.email.trim() ){
            $http({
              method: "POST",
              url: "notsecure/user/checkExternalUser.do",
        
              data:
                "email=" +
                $scope.userData.email +
                "&firstName=" +
                $scope.userData.firstName +
                "&surname=" +
                $scope.userData.surname,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
              }
            }).then(function (res) {
              if (res.data.checkWarning) {
                $scope.isExternal = true;
                $translate("error.idea.isExternal").then(function (msg) {
                  Notification.error(msg);
                });
              }else $scope.isExternal = false;
            });
        }
          };
        $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
        ];
        
        $scope.selectedEdu = $scope.eduOptions[4];
        $scope.kaptcha;
        $scope.hasError = false;
        $scope.kaptchaRefresher = {};
        $scope.register = function() {
            $scope.dataLoading = true;
            $scope.hasError = false;
            $scope.extData.education = $scope.selectedEdu.value;
            $http({
                    method: 'POST',
                    url: 'notsecure/registerUser.do',
                    data: 'userData=' + encodeURIComponent(JSON.stringify($scope.userData)) + '&extData=' + encodeURIComponent(JSON.stringify($scope.extData)) + '&kaptcha=' + $scope.kaptcha,
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    }
                })
                .then(function(res) {
                    $scope.kaptcha = null;
                    $scope.dataLoading = false;
                    $scope.msg = res.data.message;
                    if (res.data.success == true) {
                        Notification.success(res.data.data);
                        $location.path("/");
                    } else {
                        $scope.hasError = true;
                    }
                    $scope.kaptchaRefresher.invoke();
                });
        };
    }
]);