app.directive("contentViewer", [
    "$http",
    "$translate",
    "Notification",
    function ($http, $translate, Notification) {
      return {
        restrict: "E",
        scope: {
          key: "=",
        },
        templateUrl: "partial/directive/content-viewer.html",
        controller: function ($scope) {
          $scope.isLoading = true;
          $scope.hideContainer = true;
          var path = "notsecure/content-by-key.do?key=" + $scope.key;
  
          $scope.loadContent = function () {
            $http
              .get(path)
              .then(
              function (response) {
                $scope.content = response.data.data;
                $scope.isLoading = false;
                if($scope.content)$scope.hideContainer = false;
              },
              function (response) {
                $translate("error.generic").then(function (msg) {
                  Notification.error(msg);
                });
                $scope.isLoading = false;
              }
              );
          };
         
          $scope.loadContent();
        }
      };
    }
  ]);
  