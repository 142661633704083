app.controller("competitionsOpenCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate','$uibModal',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate,$uibModal) {
        $scope.authenticated = $rootScope.authenticated;
        
        $scope.loading = true;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var path = "notsecure/market/retrieveOpenProblemModel.do?start=0&limit=25";
        $http.get(path)
            .then(function (response) {
                $scope.competitions = response.data.data;
                $scope.loading = false;
            }, function (response) {
                //Second function handles error
                Notification.error('hata!');
            });
        $scope.submitIdeaRedirect = function (index, id) {
            if ($scope.competitions[index].publicMode == 2) {
                $location.path('/submit-idea-external/' + id);
            } else {
                $translate('error.login.submit.idea').then(function (msg) {
                    $scope.sureSubmitIdea($scope,id);
                });
            }
        }

        $scope.sureSubmitIdea = function (t,id) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                        $rootScope.loginMsg = "error.login.submit.idea";
                        $rootScope.loginRedirect = "/submit-idea/" + id;
                        $location.path('/login');
                        $uibModalInstance.close();
                    }
    
                    $scope.OkReg = function () {
                        $location.path('/register');
                        $uibModalInstance.close();
                    }
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureSubmitIdea.html"
            });
        };

    }]);
app.filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
        return $sce.trustAsHtml(text);
    };
}]);