app.controller("ideaExternalCtrl", ['$scope', 'Upload', '$sce', '$http', '$location', '$rootScope', '$routeParams', 'Notification', '$translate', '$uibModal',
    function ($scope, Upload, $sce, $http, $location, $rootScope, $routeParams, Notification, $translate, $uibModal) {
        $scope.isLoading = false;
        $scope.showError = false;
        $scope.showMessage = false;
        $scope.isMine = false;
        $scope.ratingAllowed = false;
        $scope.termsChecked = false;
        $scope.ref = 0;
        $scope.fileList = [];
        $scope.uploadedFiles = [];
        $scope.fileProgress = 0;
        $scope.ideaId = $routeParams.ideaId;
        $scope.competitionId = $routeParams.competitionId;
        $scope.ideaRating = { rate: 0, ideaId: $scope.ideaId };
        $scope.idea = {};
        $scope.ideaComment = {};
        $scope.isCommentShow = false;
        $scope.isCommentEnabled = false;
        $scope.ideaCriterias = [];
        $scope.extData = {};
        $scope.loadIdeaFlag = false;
        $scope.showUserForm = true;
        $scope.kaptchaRefresher = {};
        $scope.ideaFields = [];
        $scope.categories = [];
        $scope.category = {};
        var Modes = { VIEW: 0, NEW: 1, EDIT: 2 };
        $scope.mode = 0;
        $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
        ];
        
        $scope.selectedEdu = $scope.eduOptions[4];

        $scope.getRef = function () {
            var path = "notsecure/idea/getReference.do";
            
            $http({
                method: 'POST',
                url: path,

                data: 'object=idea',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.idea.referenceNumber = $scope.ref = res.data.data;
                });
        };
        
        $scope.loadCategories = function () {
            $http.get("notsecure/market/retrieveProblemCategory.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=25")
                .then(function (response) {
                    $scope.categories = response.data.data;
                    for(var i=0;i < $scope.categories.length;i++){
                        if($scope.categories[i].parent != null && $scope.categories[i].parent.name != null){
                            $scope.categories[i].selectionName = $scope.categories[i].parent.name +" > "+$scope.categories[i].name; 
                        }else{
                            $scope.categories[i].selectionName = $scope.categories[i].name; 
                        }
                    }
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Criterias');
                });

        };

        $scope.loadProblemCriteria = function () {
            var path = "notsecure/getOpenCompetitionAcceptanceCriteria.do?problemId=" + $scope.problemId;
            $http.get(path)
                .then(function (response) {
                    $scope.problemCriterias = response.data.data;
                    for (var i = 0; i < $scope.problemCriterias.length; i++) {
                        $scope.ideaCriterias.push({ accCritId: $scope.problemCriterias[i].id, description: '' });
                    }
                    if ($scope.mode == Modes.EDIT) {
                        $scope.loadIdeaCriteria();
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        
        $scope.loadCompetition = function () {
            var path = "notsecure/getOpenCompetition.do?id=" + $scope.problemId;
            $http.get(path)
                .then(function (response) {

                    $scope.competition = response.data.data[0];
                    $scope.getUploadedFiles();
                    checkIdeaComment();
                    if ($scope.competition === undefined) {
                        $location.path("/login");
                    }
                    if (($scope.mode == Modes.NEW || $scope.mode == Modes.EDIT) && $scope.competition.problemState != 'A') {
                        $location.path("/");
                    }
                    if ($scope.mode == Modes.NEW) {
                        switch ($scope.competition.publicMode) {
                            case 0:
                            case 1:
                                if ($rootScope.authenticated == false) {
                                    $location.path("/login");
                                }
                                break;
                        }
                    }

                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };
        $scope.getUploadedFiles = function () {

            var path = "notsecure/idea/retrieveIdeaFiles.do?ideaReferenceNumber=";
            if ($scope.idea.referenceNumber) $scope.ref = $scope.idea.referenceNumber;
            $http.get(path + $scope.ref + "&problemId=" + $scope.competition.id + "&start=0&limit=25")
                .then(function (response) {
                    $scope.uploadedFiles = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('ERROR suggestion files');
                });
        };
        $scope.removeFile = function (i) {
            $scope.fileList.splice(i, 1);
        };
        $scope.updateFiles = function (newFiles) {

            if (newFiles.length > 0) {
                if (($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length) > 3) {
                    Notification.error($translate.instant('error.files.max3'));
                } else {
                    for (var i = 0; i < newFiles.length; i++) {
                        $scope.fileList.push(newFiles[i]);
                    }
                    // newFiles.forEach(function (f) {
                    //     $scope.fileList.push(f);
                    // });

                    $scope.uploadEnabled = true;
                    Notification.info($translate.instant('info.files.added'));
                }
            }
        };
        $scope.deleteFile = function (id) {
            var url = "notsecure/idea/deleteFileById.do";
            
            $scope.loadDeleteFiles = $http({
                method: 'POST',
                url: url,

                data: 'fileId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.getUploadedFiles();
                    Notification.info($translate.instant('info.file.deleted'));
                });
        };
        $scope.uploadFiles = function () {
            if (($scope.fileList.length + $scope.uploadedFiles.length) > 3) {
                Notification.error($translate.instant('error.files.max3'));
                return;
            }
            if ($scope.fileList && $scope.fileList.length < 4 && $scope.fileList.length > 0) {
                handleMsg(MessageTypes.LOADING, 'info.files.uploading');
                for (var i = 0; i < $scope.fileList.length; i++) {
                    var payload = new FormData();

                    payload.append("ideaReferenceNumber", $scope.ref);
                    payload.append('problemId', $scope.problemId);
                    payload.append('file', $scope.fileList[i]);
                    payload.append('authenticated', false);
                    var url = "notsecure/idea/uploadFile.htm";
                    
                    $scope.loadUpdateFiles =  $http({
                        url: url,
                        method: 'POST',
                        ignoreLoadingBar: true,
                        data: payload,
                        //assign content-type as undefined, the browser
                        //will assign the correct boundary for us
                        headers: { 'Content-Type': undefined },
                        //prevents serializing payload.  don't do it.
                        transformRequest: angular.identity
                    }).then(function (response) {
                        if (response.data.success) {
                            $scope.fileList = [];
                            Notification.info($translate.instant("info.files.uploaded"));
                            $scope.fileList = [];
                            $scope.getUploadedFiles();
                        } else {
                            Notification.error($translate.instant(response.data.message));
                        }


                    }, function (response) {
                        if (response.status > 0) {
                            Notification.error(response.status + ': ' + response.data);
                        }
                    }, function (evt) {
                        $scope.fileProgress =
                            Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        if (evt.loaded == evt.total) { }
                    });
                }
            } else {
                Notification.error($translate.instant('error.files.max3'));
            }
        };
        $scope.normalizeIdeaFields = function (list){
            for(var i = 0; i < list.length; i++){
                if( list[i] == undefined ){
                    list[i] = {"txt" : null }
                }
                list[i].problemFieldId = $scope.competition.problemFields[i].id;
            }
            return list;
        }
        $scope.save = function () {
            
            if ($scope.fileList.length > 0) {
                Notification.error(
                  $translate.instant("info.not.uploaded.files.exists")
                );
                return;
              }
              if (!$scope.termsChecked) {
                Notification.error($translate.instant('label.terms'));
                return;
            }
            $scope.isLoading = true;
            $scope.idea.problemId = $scope.competitionId;
            
            var category = {};
            if($scope.category != undefined){              
                category.id = $scope.category.id;
            }

            var url = 'notsecure/idea/saveExternal.do';
            $scope.extData.education = $scope.selectedEdu.value;
            var arr = [];
            arr.push($scope.idea);
            var data = 'ideaData=' + encodeURIComponent(JSON.stringify(arr)) + '&ideaFields=' + encodeURIComponent(JSON.stringify($scope.normalizeIdeaFields($scope.ideaFields))) + '&userData=' + encodeURIComponent(JSON.stringify($scope.userData)) + '&extData=' + encodeURIComponent(JSON.stringify($scope.extData)) + '&kaptcha=' + $scope.kaptcha + '&category=' +encodeURIComponent(JSON.stringify(category));
            

            $http({
                method: 'POST',
                url: url,
                data: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    $scope.isLoading = false;
                    $scope.kaptchaRefresher.invoke();

                    if (res.data.success == true) {

                        Notification.info($translate.instant('info.notification.submit.idea.external'));
                        $location.path('competition-open/' + $scope.problemId);
                    } else {
                        Notification.error(res.data.message);
                    }
                });
        };
        
        function handleMsg(type, msg) {
            if (type == MessageTypes.ERROR) {
                $scope.showError = true;
                $scope.showMessage = false;
                $scope.showMessageSpinner = false;
                $scope.errorMsg = msg;
            } else if (type == MessageTypes.INFO) {
                $scope.showError = false;
                $scope.showMessage = true;
                $scope.showMessageSpinner = false;
                $scope.result = msg;
            } else if (type == MessageTypes.LOADING) {
                $scope.showError = false;
                $scope.showMessage = true;
                $scope.showMessageSpinner = true;
                $scope.result = msg;
            }
        }
        var MessageTypes = {
            ERROR: 1,
            INFO: 2

        };
        
            $scope.problemId = $routeParams.competitionId;
            $scope.getRef();
            $scope.loadCompetition();
            //$scope.loadProblemCriteria();
            $scope.mode = Modes.NEW;
            $scope.loadCategories();

        var checkIdeaComment = function () {
            if ($scope.idea.ideaUserComment == 1 && $scope.idea.idea.status == 1) {
                if ($scope.competition.problemState == 'A') {
                    $scope.isCommentEnabled = true;

                }
                $scope.isCommentShow = true;

            }
            if ($scope.idea.evalComment == 1 && $scope.idea.idea.status == 1) {
                if ($scope.competition.problemState == 'E') {
                    $scope.isCommentEnabled = true;
                    $scope.ratingAllowed = true;
                }
                $scope.isCommentShow = true;
            }
        }

        $scope.checkExternal = function () {
            $http({
                method: 'POST',
                url: 'notsecure/user/checkExternalUser.do',

                data: 'email=' + $scope.userData.email + '&firstName=' + $scope.userData.firstName + '&surname=' + $scope.userData.surname,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                }
            })
                .then(function (res) {
                    if (res.data.checkWarning) {
                        $scope.isExternal = true;
                        $translate('error.idea.isExternal').then(function (msg) {
                            Notification.error(msg);
                        });
                    }else $scope.isExternal = false;
                });
        }
        $scope.status = {
            isFirstOpen: true,
            isFirstDisabled: false
        };
        $scope.oneAtATime = true;
        $scope.ideaSaveEditModal = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                        t.save();
                        $uibModalInstance.close();
                    }

                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "ideaSaveEditModal.html"
            });
        };

    }]);