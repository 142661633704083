window.routes = {
    "/": {
        templateUrl: 'partial/home.html',
        controller: 'homeCtrl',
        requireLogin: false
    },
    "/contact": {
        templateUrl: 'partial/contact.html',
        controller: 'contactCtrl',
        requireLogin: false
    },
    "/login": {
        templateUrl: 'partial/login.html',
        controller: 'loginCtrl',
        requireLogin: false
    },
    "/login/:ssoUserName": {
        templateUrl: 'partial/login.html',
        controller: 'loginCtrl',
        requireLogin: false
    },
    "/forgotpass": {
        templateUrl: 'partial/forgotpass.html',
        controller: 'forgotpassCtrl',
        requireLogin: false
    },
    "/submit-suggestion-external": {
        templateUrl: 'partial/submit-suggestion.html',
        controller: 'submitSuggestionExternalCtrl',
        requireLogin: false
    },
    "/submit-suggestion": {
        templateUrl: 'partial/submit-suggestion.html',
        controller: 'submitSuggestionCtrl',
        requireLogin: true
    },
    "/submit-suggestion/:suggestionId": {
        templateUrl: 'partial/submit-suggestion.html',
        controller: 'submitSuggestionCtrl',
        requireLogin: false
    },
    "/register": {
        templateUrl: 'partial/register.html',
        controller: 'registerCtrl',
        requireLogin: false
    },
    "/redirect": {
        templateUrl: 'partial/redirect.html',
        controller: 'redirectCtrl',
        requireLogin: true
    },
    "/protected": {
        templateUrl: 'partial/protected.html',
        controller: 'protectedCtrl',
        requireLogin: true
    },
    "/score": {
        templateUrl: 'partial/score.html',
        controller: 'scoreCtrl',
        requireLogin: true
    },
    "/score/:userid": {
        templateUrl: 'partial/score.html',
        controller: 'scoreCtrl',
        requireLogin: true
    },
    "/profile": {
        templateUrl: 'partial/profile.html',
        controller: 'profileCtrl',
        requireLogin: true
    },
    "/suggestions": {
        templateUrl: 'partial/suggestions.html',
        controller: 'suggestionsCtrl',
        requireLogin: true
    },
    "/edit-suggestion/:suggestionId": {
        templateUrl: 'partial/suggestion-edit.html',
        controller: 'suggestionDetailCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/suggestion/:suggestionId": {
        templateUrl: 'partial/suggestion-view.html',
        controller: 'suggestionDetailCtrl',
        requireLogin: true
    },
    "/competitions": {
        templateUrl: 'partial/competitions.html',
        controller: 'competitionsCtrl',
        requireLogin: true
    },
    "/competitions-open": {
        templateUrl: 'partial/competitions.html',
        controller: 'competitionsOpenCtrl',
        requireLogin: false
    },
    "/competition/:competitionId": {
        templateUrl: 'partial/competition-view.html',
        controller: 'competitionDetailCtrl',
        requireLogin: true
    },
    "/competition-open/:competitionId": {
        templateUrl: 'partial/competition-view.html',
        controller: 'competitionDetailExternalCtrl',
        requireLogin: false
    },
    "/competition-edit": {
        templateUrl: 'partial/competition-edit.html',
        controller: 'competitionEditCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/competition-edit/:competitionId": {
        templateUrl: 'partial/competition-edit.html',
        controller: 'competitionEditCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/idea-list/:competitionId": {
        templateUrl: 'partial/idea-list.html',
        controller: 'ideaListCtrl',
        requireLogin: true
    },
    "/idea/:ideaId": {
        templateUrl: 'partial/idea-view.html',
        controller: 'ideaCtrl',
        requireLogin: true
    },
    "/submit-idea/:competitionId": {
        templateUrl: 'partial/submit-idea.html',
        controller: 'ideaCtrl',
        requireLogin: true
    },
    "/submit-idea-external/:competitionId": {
        templateUrl: 'partial/submit-idea.html',
        controller: 'ideaExternalCtrl',
        requireLogin: false
    },
    "/submit-idea/:competitionId/:ideaId": {
        templateUrl: 'partial/submit-idea.html',
        controller: 'ideaCtrl',
        requireLogin: false
    },
    "/competition-eval-report/:competitionId": {
        templateUrl: 'partial/competition-eval-report.html',
        controller: 'competitionEvalReportCtrl',
        requireLogin: true
    },
    "/eval-report/:evalId/:compititionId/:competitionCode/:competitionName": {
        templateUrl: 'partial/eval-report.html',
        controller: 'evalReportCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/report-suggestion": {
        templateUrl: 'partial/report-suggestion.html', 
        controller: 'reportSuggestionCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/report-user": {
        templateUrl: 'partial/report-user.html', 
        controller: 'reportUserCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/report-portfolio": {
        templateUrl: 'partial/report-portfolio.html', 
        controller: 'reportPortfolioCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/admin-content": {
        templateUrl: 'partial/admin-content.html', 
        controller: 'adminContentCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/admin-campaigns": {
        templateUrl: 'partial/admin-campaigns.html', 
        controller: 'adminCampaignsCtrl', 
        requireLogin: true
    },
    "/admin-rewards-points-management": {
        templateUrl: 'partial/admin-rewards-points-management.html', 
        controller: 'adminRewardsPointsManagementCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/admin-campaign-view/:campaignId": {
        templateUrl: 'partial/admin-campaign-view.html', 
        controller: 'adminCampaignViewCtrl', 
        requireLogin: true
    },
    "/campaign-view/:campaignId": {
        templateUrl: 'partial/campaign-view.html', 
        controller: 'campaignViewCtrl', 
        requireLogin: true
    },
    "/admin-campaign-edit/:campaignId": {
        templateUrl: 'partial/admin-campaign-edit.html', 
        controller: 'adminCampaignEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/admin-campaign-edit": {
        templateUrl: 'partial/admin-campaign-edit.html', 
        controller: 'adminCampaignEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/news-edit/:newsId": {
        templateUrl: 'partial/news-edit.html', 
        controller: 'newsEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/news-edit": {
        templateUrl: 'partial/news-edit.html', 
        controller: 'newsEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/news-view/:newsId": {
        templateUrl: 'partial/news-view.html', 
        controller: 'newsViewCtrl', 
    },
    "/news-list": {
        templateUrl: 'partial/news-list.html', 
        controller: 'newsListCtrl'
    },
    "/admin-content-intellectual-property": {
        templateUrl: 'partial/admin-content-intellectual-property.html', 
        controller: 'adminIntellectualPropertyContentCtrl', 
        requireLogin: true
    },
    "/project-edit": {
        templateUrl: 'partial/project-edit.html', 
        controller: 'projectEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/project-edit/:projectId": {
        templateUrl: 'partial/project-edit.html', 
        controller: 'projectEditCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/project-view/:projectId": {
        templateUrl: 'partial/project-view.html', 
        controller: 'projectViewCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/projects": {
        templateUrl: 'partial/project-list.html', 
        controller: 'projectListCtrl', 
        requireLogin: true,
        adminAccess:true
    },
    "/activation": {
        templateUrl: 'partial/activation.html',
        controller: 'activationCtrl',
        requireLogin: false,
        adminAccess:false
    },
    "/contributions/:userId/:userName": {
        templateUrl: 'partial/contributions.html',
        controller: 'contributionsCtrl',
        requireLogin: true
    },
    "/contributions": {
        templateUrl: 'partial/contributions.html',
        controller: 'contributionsCtrl',
        requireLogin: true
    },
    "/users": {
        templateUrl: 'partial/users.html',
        controller: 'usersCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/new-user": {
        templateUrl: 'partial/new-user.html',
        controller: 'newUserCtrl',
        requireLogin: true,
        adminAccess:true
    },  
    "/new-user/:userid": {
        templateUrl: 'partial/new-user.html',
        controller: 'newUserCtrl',
        requireLogin: true,
        adminAccess:true
    },  
    "/competition-settings": {
        templateUrl: 'partial/competition-settings.html',
        controller: 'competitionSettingsCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/alarm-settings": {
        templateUrl: 'partial/alarm-settings.html',
        controller: 'alarmSettingsCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/settings": {
        templateUrl: 'partial/settings.html',
        controller: 'settingsCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/submit-improvement": {
        templateUrl: 'partial/submit-improvement.html',
        controller: 'improvementSubmitCtrl',
        requireLogin: true
    },
    "/submit-improvement/:improvementId": {
        templateUrl: 'partial/submit-improvement.html',
        controller: 'improvementSubmitCtrl',
        requireLogin: true
    },
    "/improvement-transfer/:itemId": {
        templateUrl: 'partial/improvement-transfer.html',
        controller: 'improvementTransferCtrl',
        requireLogin: true
    },
    "/improvement-field": {
        templateUrl: 'partial/improvement-field.html',
        controller: 'improvementFieldCtrl',
        requireLogin: true
    },
    "/improvements": {
        templateUrl: 'partial/improvements.html',
        controller: 'improvementsCtrl',
        requireLogin: true
    },
    "/improvement/:improvementId": {
        templateUrl: 'partial/improvement-view.html',
        controller: 'improvementDetailCtrl',
        requireLogin: true
    },
    "/improvement-settings": {
        templateUrl: 'partial/improvement-settings.html',
        controller: 'improvementSettingsCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/rewards-management/:userId": {
        templateUrl: 'partial/rewards-management.html',
        controller: 'rewardsManagementCtrl',
        requireLogin: true
    },
    "/rewards-managements": {
        templateUrl: 'partial/rewards-managements.html',
        controller: 'rewardsManagementsCtrl',
        requireLogin: true
    },
    "/report-improvement": {
        templateUrl: 'partial/report-improvement.html',
        controller: 'reportImprovementCtrl',
        requireLogin: true,
        adminAccess:false
    },
    "/report-problem": {
        templateUrl: 'partial/report-problem.html',
        controller: 'reportProblemCtrl',
        requireLogin: true,
        adminAccess:true
    },
    "/report-improvement-process": {
        templateUrl: 'partial/report-improvement-process.html',
        controller: 'reportImprovementProcessCtrl',
        requireLogin: true,
        adminAccess:false
    },
    "/report-improvement-unit": {
        templateUrl: 'partial/report-improvement-unit.html',
        controller: 'reportImprovementUnitCtrl',
        requireLogin: true,
        adminAccess:false
    }    
};