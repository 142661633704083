app.controller("activationCtrl", ['$scope', '$http', '$location', 'Notification', "$translate",
    function ($scope, $http, $location, Notification, $translate) {


        var uuid = $location.search().token || '';
        var state = $location.search().state || '';
        $scope.passData = {};
        $scope.dataLoading = false;
        $scope.passwordState = false;
        $scope.activationState = false;
        $scope.invalidState = false;

        $scope.checkActivationLink = function () {

            $http.get("notsecure/validateEmail.do?token=" + uuid +"&state=" + state)
                .then(function (response) {
                    if (response.data.success) {
                        $scope.result = response.data;
                        $scope.activationState = response.data.success;
                        $scope.invalidState = false;
                        $scope.passwordState = false;
                    } else {
                        $scope.result = response.data;
                        $scope.invalidState = true;
                        $scope.activationState = response.data.success;
                        $scope.passwordState = false;
                        //Notification.error(response.data.message);
                    }


                }, function (response) {
                    Notification.error(response.data.message);
                    $scope.invalidState = true;
                });

        };

        if (typeof uuid === 'undefined' || !uuid || uuid == '') {
            Notification.warning($translate.instant("error.token.invalid"));
            $scope.invalidState = true;
        } else {
            $scope.checkActivationLink();
        }
        $scope.Return = function () {
            $location.path("/login");
        }

        $scope.passData = {

            newpassword: '',
            newpassword_confirmed: ''
        };
        var validatePassword = function () {
            if ($scope.passData.newpassword != $scope.passData.newpassword_confirmed) {
                Notification.warning($translate.instant("label.pass.missmatch"));
                return false;
            }
            return true;
        }
        $scope.changePassword = function (passData) {

            if (validatePassword()) {

                $scope.dataLoading = true;
                $http({
                    method: 'POST',
                    url: 'notsecure/user/createNewPassword.do',
                    transformRequest: function (obj) {
                        var str = [];
                        for (var p in obj)
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        return str.join("&");
                    },
                    data: { token: uuid, newpassword: passData.newpassword, newpassword_confirmed: passData.newpassword_confirmed },
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    }
                })
                    .then(function (res) {
                        $scope.dataLoading = false;
                        if (res.data.success) {
                            $scope.passData = {};
                            $scope.changepassreturnmessage = res.data.data;
                            $scope.passwordState = true;
                            $scope.activationState = false;
                            $scope.invalidState = false;
                            // $translate('info.pass.changed').then(function (msg) {
                            //     Notification.info(msg);
                            // });
                        } else {
                            Notification.error(res.data.message);
                        }
                    });

            }

        };

    }]);