var app = angular.module('innoApp', ['pascalprecht.translate', 'ngCookies', 'ngRoute', 'ngFileUpload',
    'chieffancypants.loadingBar',
    'ngAnimate', 'ui.bootstrap', 'chart.js', 'ngSanitize', 'ui-notification', 'ui.tree', 'textAngular', 'TT.multiLineEllipsis', 'AngularPrint', 'cgBusy'
]);
app.config(['$translateProvider', '$httpProvider', function ($translateProvider, $httpProvider) {
    
    var prefferedLang = "tr";
        /* var lang = window.navigator.language.toLowerCase();
        if (lang && lang.startsWith("tr")) prefferedLang = "tr";
        else prefferedLang = "tr"; */
    
    
    $translateProvider
        .useStaticFilesLoader({
            //prefix: 'translations/',
            prefix: 'notsecure/translation.do?lang=',
            suffix: ''
        })
        .preferredLanguage(prefferedLang)
        .useLocalStorage()
        .useMissingTranslationHandlerLog();
    $translateProvider.useSanitizeValueStrategy(null);
    $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = CSRF_TOKEN;
    $httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";
    $httpProvider.defaults.headers.common.Pragma = "no-cache";
    $httpProvider.defaults.headers.common["If-Modified-Since"] = "0";
}]);

app.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
    for (var path in window.routes) {
        $routeProvider.when(path, window.routes[path]);
    }
    //$routeProvider.otherwise({redirectTo: '/'});
    // enable html5Mode for pushstate ('#'-less URLs)
    //$locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('');
}]);
app.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.latencyThreshold = 5;
    cfpLoadingBarProvider.parentSelector = '#content-holder';
}]);


app.run(['$rootScope', '$location', 'AuthService', '$http', 'Notification', '$translate', '$uibModal', '$timeout',
    function ($rootScope, $location, AuthService, $http, Notification, $translate, $uibModal, $timeout) {

        $rootScope.title = "innocentrum";
        $rootScope.lang = 'tr';
        if (window.IS_LOGGEDIN == 'true') {
            $rootScope.currentUser = {
                id: window.USER_ID,
                firstName: window.FIRSTNAME,
                fullName: window.FULLNAME,
                username: window.USERNAME,
                role: window.USER_ROLE,
                isExternal: window.IS_EXTERNAL
            }
            $rootScope.authenticated = true;
        } else {
            $rootScope.authenticated = false;
        }

        $rootScope.sessionTimeout = 2880 * 60 * 1000;
        $rootScope.sessionTimeoutWarning = 2878 * 60 * 1000;
        $rootScope.sessionExpritationPrompt = function () {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'sessionTimeoutModal.html'

            });
        }
        $rootScope.sessionKill = function () {
            window.location.href = 'j_spring_security_logout';
        }
        $rootScope.sessionExtend = function () {
            $http.get("notsecure/user/pingUser.do");
            $rootScope.logOutModalClose();
        }
        $rootScope.sessionExtendForUser = function () {

            $http.get("notsecure/user/pingUser.do");
        }
        $rootScope.setSessionTimers = function () {

            if ($rootScope.authenticated) {
         
                $rootScope.sessionExtenderTimer ? $timeout.cancel($rootScope.sessionExtenderTimer) : null;
                $rootScope.sessionExpritationTimer ? $timeout.cancel($rootScope.sessionExpritationTimer) : null;
                $rootScope.sessionKillTimer ? $timeout.cancel($rootScope.sessionKillTimer) : null;
                $rootScope.sessionExpritationTimer = $timeout($rootScope.sessionExpritationPrompt, $rootScope.sessionTimeoutWarning);
                $rootScope.sessionKillTimer = $timeout($rootScope.sessionKill, $rootScope.sessionTimeout);
            } else {
                $rootScope.sessionExtenderTimer ? $timeout.cancel($rootScope.sessionExtenderTimer) : null;
                $rootScope.sessionExtenderTimer = $timeout($rootScope.sessionExtendForUser, $rootScope.sessionTimeoutWarning);
            }

        }

        $rootScope.logOut = function () {
            Notification.info({ message: '<form class="horizontal"><a class="btn btn-default btn-sml" href="j_spring_security_logout">' + $translate.instant("label.yes") + '</a>&nbsp;<a class="btn btn-default btn-sml">' + $translate.instant("label.no") + '</a></form>', title: $translate.instant("label.sure.exit") });
        }
        $rootScope.logOutModal = function () {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'exitModal.html'

            });
        };
        $rootScope.submitSuggModal = function () {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'submit-sugg-modal.html'
            });
        };
        $rootScope.logOutModalClose = function () {
            $rootScope.modalInstance.close();
        };
        $rootScope.$on("$routeChangeStart", function (event, next, current) {
            $rootScope.postLogInRoute;
            for (var i in window.routes) {
                if (next.originalPath == i) {
                    if (window.routes[i].requireLogin && !$rootScope.authenticated) {
                        event.preventDefault();
                        $rootScope.postLogInRoute = $location.path();
                        $location.path("/login");
                    } else if ($rootScope.authenticated && $rootScope.currentUser && !$rootScope.currentUser.authorization) {

                        AuthService.authorize().then(function (data) {
                            $rootScope.currentUser = data;
                            if ($rootScope.postLogInRoute) {
                                $location.path($rootScope.postLogInRoute);
                                postLogInRoute = null;
                            }
                        })
                    } else if ($rootScope.authenticated && $rootScope.postLogInRoute) {
                        $location.path($rootScope.postLogInRoute);
                        postLogInRoute = null;
                    }
                    if (window.routes[i].adminAccess && $rootScope.currentUser && $rootScope.currentUser.authorization) {
                        if ($rootScope.currentUser.authorization.hasAdminAccess === false) {
                            $location.path("/protected");
                            Notification.warning($translate.instant('error.unauthorized'));
                        }

                    }
                    if (!$rootScope.frontendConfig) {
                        AuthService.frontendConfig().then(function (data) {
                            $rootScope.frontendConfig = data;
                        })
                    }
                }
            }
        });
        $rootScope.$on('$routeChangeSuccess', function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            $rootScope.isNavCollapsed = true;
            $translate.onReady(function () {
                $rootScope.title = $translate.instant('label.portal.header') + " - Powered by innoCentrum";
                document.title = $rootScope.title;
            });
        });
    }]);

app.controller("homeCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope',
    function ($scope, $sce, $http, $location, $rootScope) {
        if ($rootScope.authenticated == true) {
            $location.path("/protected");
        }

        $scope.getPublicNewsList = function(){
            var path = "notsecure/news/getPublicNewsList.do?start=0&limit=4";
            $http.get(path)
                .then(function (response) { //http.get ile alakalı bütün işlemler bitip cevap alındığında devam ediliyor.
                    $scope.newsList = response.data.data; //backend tarafından gönderilen isteğin cevabı
                }, function (response) {
                    //Second function handles error
                //    Notification.error('hata!');
                });
        };
        $scope.getPublicNewsList();
    }
]);
app.controller("loginCtrl", ['$scope', '$sce', '$http', '$location', 'AuthService', '$rootScope', "$cookies", "$route","$translate","$routeParams",
    function ($scope, $sce, $http, $location, AuthService, $rootScope, $cookies, $route,$translate,$routeParams) {
    $scope.ssoUserName = $routeParams.ssoUserName;
        $scope.credentials = {
            j_username: '',
            j_password: '',
            clientEnv: 'desktop',
            kaptcha: '',
            ssoUserName: $scope.ssoUserName
        };
        $scope.passwordmessage = $translate.instant('label.regular.user.password.example');
        $scope.dataLoading = false;
        $scope.hasError = false;
        $scope.kaptchaUrl = 'notsecure/captcha-image.do';
        $scope.redirect = $rootScope.postLogInRoute ? $rootScope.postLogInRoute : "/protected";

        if ($rootScope.loginMsg != null) {
            $scope.hasError = true;
            $scope.msg = $rootScope.loginMsg;
        }

       

        $scope.login = function (credentials) {
            $scope.dataLoading = true;
            AuthService.login(credentials).then(function (data) {
                $scope.dataLoading = false;
                $scope.hasError = false;
                $rootScope.authenticated = true;
                $rootScope.currentUser = data;
                $scope.showCaptcha = false;
                AuthService.frontendConfig().then(function (data) {
                    $rootScope.frontendConfig = data;
                })
                $location.path($scope.redirect);
                $rootScope.postLogInRoute = null;
            }, function (data) {
                $scope.hasError = true;
                $scope.dataLoading = false;
                $scope.msg = "error.login.credential";
                

                if (data.data.isLocked) {
                    $scope.msg = "error.account.locked";
                    $scope.showCaptcha = false;
                } else {
                    $scope.msg = "error.login.credential";
                    $scope.showCaptcha = data.data.isCaptchaRequired;
                    $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();
                }
                $rootScope.currentUser = null;
                $rootScope.authenticated = false;
            });

        };

        if( $scope.ssoUserName){
            $scope.login( $scope.credentials)
        }
    }
]);

app.controller("forgotpassCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', 'Notification',
    function ($scope, $sce, $http, $location, $rootScope, Notification) {
        $scope.dataLoading = false;
        $scope.forgotpass = function () {
            $scope.dataLoading = true;
            $http({
                method: 'POST',
                url: 'notsecure/forgotpass.do',
                data: 'j_username=' + $scope.username,
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (res) {
                    $scope.dataLoading = false;
                    if (res.data.success) {
                        Notification.success(res.data.data);
                        $location.path('/login');
                    } else {
                        Notification.error(res.data.message);
                    }
                });
        }
    }
]);
app.controller('LanguageSwitchController', ['$scope', '$rootScope', '$translate', '$http',
    function ($scope, $rootScope, $translate, $http) {
        $scope.changeLanguage = function (langKey) {
            $translate.use(langKey).then(function () {
                $http.defaults.headers.common["CONTENT-LANG"] = langKey;
                var url = "notsecure/user/saveSettingsUnauthorized.do";
                if ($rootScope.authenticated) {
                    url = 'private/user/saveSettings.do';
                }
                $http({
                    method: 'POST',
                    url: url,

                    data: "data={lang:'" + langKey + "'}",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    }
                })
                    .then(function (res) {
                        $scope.dataLoading = false;
                        if (res.data.success) {
                            window.location.reload();
                        } else if (res.status === 302 || res.status === 200) { window.location.reload(); } else {
                            //alert(res.data.message);
                        }
                    });
            });

        };

        $rootScope.$on('$translateChangeSuccess', function (event, data) {
            var language = data.language;
            $rootScope.lang = language;
        });
    }
]);
app.controller('openCompetitionCtrl', ['$http', '$scope', '$sce', '$location', '$translate', '$rootScope', '$uibModal', function ($http, $scope, $sce, $location, $translate, $rootScope, $uibModal) {
    
   
    $http.get("notsecure/market/retrieveOpenProblemModel.do?start=0&limit=25")
        .then(function (response) {
            $scope.slides = response.data.data;
            for (var i = 0; i < $scope.slides.length; i++) {
                var pid = $scope.slides[i].id;
                $http.get("notsecure/getOpenCompetitionRewards.do?problemId=" + pid)
                    .then(function (response) {
                        var r = response.data.data;
                        if (r != undefined && r.length > 0) {
                            for (var j = 0; j < $scope.slides.length; j++) {
                                if ($scope.slides[j].id == r[0].problemId) {
                                    $scope.slides[j].rewards = r;
                                    break;
                                }
                            }
                        }
                    }, function (response) {

                    });
            }
        }, function (response) {
            //Second function handles error
            //alert('hata!');
        });
    $scope.slideInterval = 6000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
    $scope.submitIdeaRedirect = function (index, id) {
        if ($scope.slides[index].publicMode == 2) {
            $location.path('/submit-idea-external/' + id);
        } else {
            $scope.sureSubmitIdea($scope, id);

        }
    }

    $scope.sureSubmitIdea = function (t, id) {
        $rootScope.modalInstance = $uibModal.open({
            animation: true,backdrop: 'static',
            controller: function ($scope, $uibModalInstance) {
                $scope.Ok = function () {
                    $rootScope.loginMsg = "error.login.submit.idea";
                    $rootScope.loginRedirect = "/submit-idea/" + id;
                    $location.path('/login');
                    $uibModalInstance.close();
                }

                $scope.Close = function () {
                    $uibModalInstance.close();
                }
            },
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "sureSubmitIdea.html"
        });
    };

}]);
app.controller('contactCtrl', ['$http', '$scope', '$sce', '$location', '$translate', '$rootScope', 'Notification', function ($http, $scope, $sce, $location, $translate, $rootScope, Notification) {

    $scope.loading = false;
    $scope.isMesssageSubmit = false;
    $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();

    $scope.send = function () {
        $scope.loading = true;
        if ($scope.contact.phone == null) $scope.contact.phone = "";
        if ($scope.contact.company == null) $scope.contact.company = "";
        if ($scope.contact.subject == null) $scope.contact.subject = "";
        $http({
            method: 'POST',
            url: 'notsecure/contact.do',
            data: "contact=" + encodeURIComponent(JSON.stringify($scope.contact)) +"&kapthca=" +$scope.credentials.kapthca,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
            .then(function (res) {
                $scope.loading = false;
                if (res.data.success) {
                    $translate('info.contact.success').then(function (msg) {
                        Notification.success(msg);
                        $scope.isMesssageSubmit = true;
                    });
                } else {
                    $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();
                    Notification.error(res.data.message);
                }
            });
    }
}]);
app.factory('AuthService', function ($http, $q, $httpParamSerializerJQLike) {
    var authService = {};
    authService.login = function (credentials) {
        var encrypted = CryptoJS.SHA256(credentials.kapthca).toString();
        return $http({
            method: 'POST',
            url: 'j_spring_security_check',
            transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
            },
            data: { clientEnv: 'desktop', j_username: credentials.j_username, j_password: credentials.j_password, kaptcha: encrypted , ssoUserName: credentials.ssoUserName  },
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        })
            .then(function (res) {

                var hasAdminAccess = false;
                for (var i = 0; i < res.data.roles.length; i++) {
                    if (res.data.roles[i].roleName == 'ROLE_ADMIN')
                        hasAdminAccess = true;
                }

                var authorization = {
                    role: res.data.role,
                    hasAdminAccess: hasAdminAccess
                }

                var currentUser = {
                    id: res.data.id,
                    firstName: res.data.firstName,
                    fullName: res.data.fullName,
                    username: res.data.username,
                    role: res.data.role,
                    externalState: res.data.isExternal,
                    authorization: authorization

                }

                return authService.authorize();
            });
    };
    authService.authorize = function () {
        return $http.get("private/user/retrieveAuthorized.do?start=0&limit=25")
            .then(function (response) {
                if (response.data.success) {
                    var a = response.data.data[0];
                    var hasAdminAccess = false;
                    for (var i = 0; i < a.roles.length; i++) {
                        if (a.roles[i].roleName == 'ROLE_ADMIN')
                            hasAdminAccess = true;
                    }
                    var authorization = {
                        role: a.role,
                        hasAdminAccess: hasAdminAccess,
                        managerForImp: a.managerForImp,
                        auditorForImp: a.auditorForImp,
                        responsibleForImp: a.responsibleForImp

                    }
                    var currentUser = {
                        id: a.id,
                        firstName: a.firstName,
                        fullName: a.fullName,
                        username: a.username,
                        role: a.role,
                        externalState: a.isExternal,
                        authorization: authorization
                    }
                    return currentUser;
                } else return null;
            });
    }
    authService.frontendConfig = function () {
        return $http.get("notsecure/app/retrieveFrontendConfig.do")
            .then(function (response) {
                return response.data.data;
            });
    }
    return authService;
});
app.filter('antislash', function () {
    return function (input) {
        if (input == null || input.length === 0) {
            return null;
        } else {
            return '\u00A0/\u00A0';
        }
    }
});
app.filter('cut', function () {
    return function (value, wordwise, max, tail) {
        if (!value) return '';

        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(' ');
            if (lastspace !== -1) {
                //Also remove . and , so its gives a cleaner result.
                if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
                    lastspace = lastspace - 1;
                }
                value = value.substr(0, lastspace);
            }
        }

        return value + (tail || ' …');
    };
});
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}
if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {

            // Steps 1-2.
            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var O = Object(this);

            // Steps 3-5.
            var len = O.length >>> 0;

            // Steps 6-7.
            var start = arguments[1];
            var relativeStart = start >> 0;

            // Step 8.
            var k = relativeStart < 0 ?
                Math.max(len + relativeStart, 0) :
                Math.min(relativeStart, len);

            // Steps 9-10.
            var end = arguments[2];
            var relativeEnd = end === undefined ?
                len : end >> 0;

            // Step 11.
            var final = relativeEnd < 0 ?
                Math.max(len + relativeEnd, 0) :
                Math.min(relativeEnd, len);

            // Step 12.
            while (k < final) {
                O[k] = value;
                k++;
            }

            // Step 13.
            return O;
        }
    });
}

if (typeof Object.assign != 'function') {
    Object.assign = function (target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource != null) { // Skip over if undefined or null
                for (var nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    };
}
app.factory("errorInterceptor", [
    "$q",
    "$location",
    "$translate",
    "$rootScope",
    "$window",
    function ($q, $location, $translate, $rootScope, $window) {
        return {
            request: function (config) {
                return config || $q.when(config);
            },
            requestError: function (request) {
                return $q.reject(request);
            },
            response: function (response) {
                if (response.headers()['content-type'] === "application/json") {
                    $rootScope.setSessionTimers();
                }
                return response || $q.when(response);
            },
            responseError: function (response) {
                if (response && response.status == 901) {
                    $rootScope.authenticated = false;
                    $rootScope.currentUser = null;
                    $q.resolve(response);
                    $location.path("/login");
                    return $q.resolve(response);
                }
                if (response && response.status == 403 && response.headers('X-INVALID-CSRF-TOKEN') == 'true') {

                    // var redirect = $rootScope.postLogInRoute ? $rootScope.postLogInRoute : "/protected";
                    //window.location = redirect;
                    window.location.reload(true);

                    return $q.resolve(response);
                }
                return $q.reject(response);
            }
        };
    }
]);
app.factory('dateFormat', function () {
    return {
        formatter: function (a) {
            if (a != undefined || a != null) {
                if (typeof (a) != 'string') {
                    var dd = a.getDate();
                    var mm = a.getMonth() + 1; //January is 0!

                    var yyyy = a.getFullYear();
                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                    var date = mm + '/' + dd + '/' + yyyy;
                    return date;
                }
            }

            return a;
        }
    };
});
app.factory('httpRequestInterceptor', ['$translate', function ($translate) {
    return {
        request: function (config) {

            config.headers["CONTENT-LANG"] = $translate.use();
            return config;
        }
    };
}]);

app.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("httpRequestInterceptor");
        $httpProvider.interceptors.push("errorInterceptor");
    }
]);
angular.lowercase = function (text) {
    return text ? text.toLowerCase() : '';
};
app.value('cgBusyDefaults', {
    templateUrl: 'angular-busy.html',
    delay: 300,
    minDuration: 700
});


app.directive('consent',["$cookies", function ($cookies) {
  return {
    scope: {},
    template:
      '<div class="consentIn">' +
      '<div class="consentOut clearfix" ng-hide="consent()">' +
      '<div class="consentClose"><a href="" ng-click="consent(true)"><i style="color:red;" class="fa fa-window-close"></i></a></div>' +
      '<p> {{"label.cookies" | translate}}</p>'+
      '<content-modal content-name="\'privacy-platform\'" label="\'label.cookies.privacy.policy\'"/>'+
      '</div>' +
      '</div>',

    controller: function ($scope) {
        

      var _consent = $cookies.get('consent');
      $scope.consent = function (consent) {
          console.log(_consent)
        if (consent === undefined) {
          return _consent;
        } else if (consent) {
            
            var expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 1000);
          $cookies.put('consent', true, {expires: expireDate});
          _consent = true;        
        }
      };
    }
  };
}]);

