function Param(value) {
  this.value = value;
}
Param.prototype.eq = function(val) {
  return this.value === val;
};
Param.prototype.notEq = function(val) {
  return this.value !== val;
};
Param.prototype.in = function() {
  var args = [].slice.call(arguments);
  return !!~args.indexOf(this.value);
};
Param.prototype.notIn = function() {
  var args = [].slice.call(arguments);
  return !~args.indexOf(this.value);
};
Param.prototype.valueOf = function() {
  return this.value;
};
Param.prototype.val = function() {
  return this.value;
};

function SuggConf(state, isEditor, isExpert, isAdmin, userId, ownerId) {
  var s = new Param(state);
  var m = new Param(isEditor);
  var e = new Param(isExpert);
  var a = new Param(isAdmin);
  var u = new Param(userId);
  var o = ownerId;
  return ({
    ratingAllowed: s.eq(12) && u.notEq(o),
    commentAllowed: s.eq(12),
    showComments: s.in(12,4,5,8,9,10) && (a.val() || e.val() || m.val() || u.eq(o)),
    showEditorComment: s.in(12,4,5,8,9,10) && (u.eq(o) || m.val() || a.val()),
    allowEditorComment: s.eq(12) && (m.val() || u.eq(o)),
    showPreevalForm: s.eq(12) && m.val(),
    showExpertComment: (s.in(12,4,5,8,9,10) && (m.val() || e.val() || a.val())),
    allowExpertComment: s.eq(12) && e.val(),
    showExpertInfo:s.in(12,4,5,8,9,10) && (a.val() || m.val()),
    showAdministration: a.val() || m.val(),
    showAssignmentInfo: (m.val() || a.val()) && s.notIn(4,11),
    showEditorAssignInfo: s.eq(2) && a.val(),
    showAssignEditor: s.in(2,12) && a.val(),
    showAssignExpert: s.eq(12) && m.val(),
    showApproveRejectForm: (s.eq(2) && a.val()) || (s.in(12) && (m.val() || a.val())) || (s.in(5) && a.val()),
    showJudgementDesc: s.in(4,11,5,8,9,10) && (a.val() || m.val() || u.eq(o)),
    checkTykUser: s.eq(5) && u.notEq(o),
    showPreevalResult: s.in(4,5,8,9,10,12) && (a.val() || m.val()),
    showPreevalResultToTyk: s.in(5,8,9,10),
    showEvalResult: s.in(5,8,9,10) && (a.val() || m.val()),
    showReward: s.in(8,10) && (a.val() || m.val() || u.eq(o)),
    showInformSection: s.in(4,5,8,9,10,11) && u.eq(o),
    showUserDetail: isAdmin,
    showActualBenefitForm: s.in(8,10) && (m.val() || a.val()),
    suggTypeEditable:s.in(2,12) && a.val(),
    showCompleteSuggestionSection: s.in(8) && (m.val() || a.val()),
    showAssignUser: s.eq(12) && (m.val() || a.val()),
  });
}
