app.directive("contentModal", [
    "$http",
    "$translate","$uibModal",
    function($http, $translate,$uibModal) {
      return {
        restrict: "E",
        scope: {
          label: "=",
          contentName:"="
        },
        templateUrl: "partial/directive/content-modal.html",
        controller: function($scope) {
            $scope.openContentModal = function() {
                var modalInstance = $uibModal.open({
                    animation: true,backdrop: 'static',
                    templateUrl: 'ContentModal.html',
                    controller: 'contentModalCtrl',
                    size: 'lg',
                    resolve: {
                        contentName: function () {
                          return $scope.contentName;
                        }
                      }
                  });
          
                modalInstance.result.then(function() {
                }, function() {
                });
              };
        }
      };
    }
  ]);
  app.controller('contentModalCtrl', function ($uibModalInstance, contentName, $http,$scope,Notification) {
    var $ctrl = this;
    $scope.contentName = contentName;
    $scope.cancel = function () {
      $uibModalInstance.close();
    };
  });
  