app.directive("kaptcha", [
    "$http",
    "$translate",
    "Notification",
    function ($http, $translate, Notification) {
      return {
        restrict: "E",
        scope: {
          key: "=",
          show:"=",
          refresher:"="
        },
        templateUrl: "partial/directive/kaptcha.html",
        controller: function ($scope) {
          
          $scope.hasError = true;
          $scope.refresh = function(){
              $scope.kaptchaUrl = 'notsecure/captcha-image.do?_ts=' + new Date().getTime();
          }
          if($scope.show) $scope.refresh();
          $scope.$watch('key', function (newValue, oldValue) {
            $scope.hasError =$scope.key == undefined ? 0 : $scope.key.length != 6;
          });
          $scope.$watch('refresher', function (value) {
            if(value){
            $scope.refresherObj = value;
                $scope.refresherObj.invoke = function(){
                  $scope.key = "";
                  $scope.refresh();
                }
            }    
        });
        }
      };
    }
  ]);
  