app.directive("sortableColumn",["$compile","$translate",function ($compile,$translate) {
    function link(scope, element, attrs) {
        element.attr('style', 'cursor: pointer;');

        var col = attrs.sortableColumn;
        scope.sortAs = '';
        element.bind('click', function () {
            var t = setInterval(function () {
                if (scope.sortAs === '') {
                    scope.sortAs = col;
                } else {
                    if (scope.sortAs === col) {
                        scope.sortAs = '-' + col;
                    } else if (scope.sortAs === '-' + col) {
                        scope.sortAs = '';
                    } else {
                        scope.sortAs = col;
                    }
                }
                clearInterval(t);
                scope.$apply();
            }, 0);
        });
        
        element.text(element.text() + ' ');
        var itext = element[0].innerHTML;
        itext = $translate.instant(itext);
        element[0].innerHTML = itext;
        element.append($compile('<i class="fa fa-sort" data-ng-show="sortAs!==\'' + col + '\' && sortAs!==\'-' + col + '\'"></i>')(scope));
        element.append($compile('<i class="fa fa-sort-asc" data-ng-show="sortAs===\'' + col + '\'"></i>')(scope));
        element.append($compile('<i class="fa fa-sort-desc" data-ng-show="sortAs===\'-' + col + '\'"></i>')(scope));
    }
    return {
        link: link
    };
}]);