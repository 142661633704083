
app.controller("submitSuggestionExternalCtrl", ["$scope", "Upload", "$timeout", "$sce", "$http", "$location", "$routeParams", "$rootScope", "$translate", "Notification", "textAngularManager","$uibModal", function ($scope, Upload, $timeout, $sce, $http, $location, $routeParams, $rootScope, $translate, Notification, textAngularManager,$uibModal) {
  $scope.isLoading = false;
  $scope.isNew = true;
  $scope.showSaveDraft = $rootScope.authenticated;
  $scope.showSubmit = true;
  $scope.termsChecked = false;
  $scope.termsChecked1 = false;
  $scope.termsChecked2 = false;
  $scope.showUserForm = true;
  $scope.isExternal = false;
  $scope.isOwnerSharesValid=true;
  $scope.kaptchaRefresher = {};
  $scope.extData = { idno: 0, education: 3 };
  $scope.eduOptions = [
            {value:0, label:"label.edu.primary"},
            {value:1, label:"label.edu.secondary"},
            {value:2, label:"label.edu.high"},
            {value:3, label:"label.edu.college"},
            {value:4, label:"label.edu.undergraduate"},
            {value:5, label:"label.edu.graduate"},
            {value:6, label:"label.edu.phd"},
  ];
  
  $scope.selectedEdu = $scope.eduOptions[4];
  
  $scope.getRef = function () {
    var path = "notsecure/idea/getReference.do";
    $http({
      method: "POST",
      url: path,

      data: "object=sugg",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.ref = res.data.data;
    });
  };
    $scope.id = 0;
    $scope.getRef();

  $scope.fileList = [];
  $scope.uploadedFiles = [];
  $scope.fileProgress = 0;
  $scope.showError = false;
  $scope.showMessage = false;
  $scope.suggestion = { rating: 0 };
  $scope.suggestionTypes = {};
  $scope.campaigns = {};
  $scope.ref = 0;

  $scope.removeFile = function (i) {
    $scope.fileList.splice(i, 1);
  };
  $scope.updateFiles = function (newFiles) {
    if (newFiles.length > 0) {
      if ($scope.fileList.length + newFiles.length + $scope.uploadedFiles.length > 3 ) {
        handleMsg(MessageTypes.ERROR, "error.files.max3");
      } else {

        for(var i = 0; i<newFiles.length;i++){
          $scope.fileList.push(newFiles[i]);
        }

        // newFiles.forEach(function (f) {
        //   $scope.fileList.push(f);
        // });

        $scope.uploadEnabled = true;
        Notification.info($translate.instant("info.files.added"));
      }
    }
  };
  $scope.uploadFiles = function () {
    if ($scope.fileList.length + $scope.uploadedFiles.length > 3) {
      handleMsg(MessageTypes.ERROR, "error.files.max3");
      return;
    }
    if (
      $scope.fileList &&
      $scope.fileList.length < 4 &&
      $scope.fileList.length > 0
    ) {
      var url = "notsecure/suggestion/uploadFile.htm?suggReferenceNumber=";
      
     $scope.loadUpdateFiles = Upload.upload({
        url: url + $scope.ref,
        ignoreLoadingBar: true,
        data: {
          files: $scope.fileList
        }
      }).then(
        function (response) {
          if (response.data.success) {
            $scope.fileList = [];
            Notification.info($translate.instant("info.files.uploaded"));
            $scope.fileList = [];
            $scope.getUploadedFiles();
          } else {
            Notification.error($translate.instant(response.data.message));
          }
        },
        function (response) {
          if (response.status > 0) {
            Notification.error(response.status + ": " + response.data);
          }
        },
        function (evt) {
          $scope.fileProgress = Math.min(
            100,
            parseInt(100.0 * evt.loaded / evt.total)
          );
          if (evt.loaded == evt.total) {
          }
        }
      );
    } else {
      Notification.error($translate.instant("error.files.max3"));
    }
  };
  $scope.getSuggTypes = function () {
    var path = "notsecure/suggestion/retrieveSuggestionTypesByLang.do";
    
    $http.get(path).then(
      function (response) {
        $scope.suggestionTypes = response.data.data;
        if ($scope.isNew) {
          $scope.suggestion.type = $scope.suggestionTypes[0].id;
        }
      },
      function (response) {
        //Second function handles error
        Notification.error("ERROR suggestion types");
      }
    );
  };

  $scope.getUploadedFiles = function () {
    var authenticated = false;
    var path =
      "notsecure/suggestion/retrieveSuggFiles.do?suggReferenceNumber=";
    
    $http
      .get(
        path + $scope.ref + "&start=0&limit=25&authenticated=" + authenticated
      )
      .then(
        function (response) {
          $scope.uploadedFiles = response.data.data;
        },
        function (response) {
          //Second function handles error
          Notification.error("ERROR suggestion files");
        }
      );
  };
  $scope.deleteFile = function (id) {
    var path = "notsecure/suggestion/deleteFileById.do";
    
    $http({
      method: "POST",
      url: path,

      data: "fileId=" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      $scope.getUploadedFiles();
      Notification.info($translate.instant("info.file.deleted"));
    });
  };
  $scope.checkExternal = function () {
    $http({
      method: "POST",
      url: "notsecure/user/checkExternalUser.do",

      data:
        "email=" +
        $scope.userData.email +
        "&firstName=" +
        $scope.userData.firstName +
        "&surname=" +
        $scope.userData.surname,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    }).then(function (res) {
      if (res.data.checkWarning) {
        $scope.isExternal = true;
        $translate("error.idea.isExternal").then(function (msg) {
          Notification.error(msg);
        });
      }else{
        $scope.isExternal = false;
      }
    });
  };
  $scope.save = function (toSubmit) {
    
    
    $scope.suggestion.referenceNumber = $scope.ref;

    if ($scope.fileList.length > 0) {
      Notification.error(
        $translate.instant("info.not.uploaded.files.exists")
      );
      return;
    }
    if (!$scope.termsChecked) {
      Notification.error($translate.instant('label.terms'));
      return;
    }
    if (!$scope.termsChecked1) {
      Notification.error($translate.instant('label.terms'));
      return;
    }
    if (!$scope.termsChecked2) {
      Notification.error($translate.instant('label.terms'));
      return;
    }
  $scope.isLoading = true;
    delete $scope.suggestion.entrydate;
    delete $scope.suggestion.updateDate;
    delete $scope.suggestion.completedDate;
      $scope.extData.education = $scope.selectedEdu.value;
      $http({
        method: "POST",
        url: "notsecure/suggestion/saveExternal.do",

        data:
          "suggData=" +
          encodeURIComponent(JSON.stringify(new Array($scope.suggestion))) +
          "&userData=" +
          encodeURIComponent(JSON.stringify($scope.userData)) +
          "&extData=" +
          encodeURIComponent(JSON.stringify($scope.extData)) +
          "&kaptcha=" +
          $scope.kaptcha,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      }).then(function (res) {
        $scope.isLoading = false;
        $scope.kaptchaRefresher.invoke();
        if (res.data.success == true) {
          //handleMsg(MessageTypes.INFO, "info.notification.submit.sugg.external");
          $translate("info.notification.submit.sugg.external").then(function (msg) {
            Notification.success(msg);
          });
          $location.path("/");
        } else {
          Notification.error(res.data.message);
        }
      });
    
  };
  $scope.suggestionCoresponder = {
    id: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.id,
    fullName: $rootScope.currentUser == undefined ? 0 : $rootScope.currentUser.fullName,
    share: 100
  }
  
  
  $scope.getSuggTypes();
 

  function handleMsg(type, msg) {
    if (type == MessageTypes.ERROR) {
      $scope.showError = true;
      $scope.showMessage = false;
      $scope.errorMsg = msg;
    } else if (type == MessageTypes.INFO) {
      $scope.showError = false;
      $scope.showMessage = true;
      $scope.result = msg;
    }
  }
  var MessageTypes = {
    ERROR: 1,
    INFO: 2
  };
  $scope.suggestionSaveEditModal = function (t) {
    $rootScope.modalInstance = $uibModal.open({
      animation: true,backdrop: 'static',
      controller: function ($scope, $uibModalInstance) {
        $scope.Ok = function () {
          t.save(true);
          $uibModalInstance.close();
        }

        $scope.Close = function () {
          $uibModalInstance.close();
        }
      },
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "suggestionSaveEditModal.html"
    });
  };
}]);
