app.controller("competitionDetailExternalCtrl", ['$scope', '$sce', '$http', '$location', '$rootScope', '$routeParams', '$translate', "$uibModal", 'Notification',
    function ($scope, $sce, $http, $location, $rootScope, $routeParams, $translate, $uibModal, Notification) {
        $scope.competitionId = $routeParams.competitionId;
        $scope.isPrivate = false;
        $scope.showEvalReport = false;
        $scope.sortStr = 'idea.enterDate desc';
        $scope.filePath = 'notsecure/download.htm';
        var myDate = new Date();
        var month = myDate.getMonth();
        $scope.datenow = myDate.getFullYear() + "-" + (month < 9 ? ('0' + (month + 1)) : (month + 1)) + "-" + myDate.getDate();
        $scope.loadCompetition = function () {
            var path = "notsecure/getOpenCompetition.do?id=" + $scope.competitionId;
           
            $http.get(path)
                .then(function (response) {
                    $scope.competition = response.data.data[0];
                    $scope.competition.description = $sce.trustAsHtml(response.data.data[0].description);
                    $scope.loadFiles();
                        $scope.criterias = $scope.competition.acceptanceCriteria;
                        $scope.rewards = $scope.competition.rewards;
                    
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: competition');
                });
        };

        $scope.loadFiles = function () {
            var path = "notsecure/getOpenCompetitionFiles.do?id=" + $scope.competitionId;
            
            $http.get(path)
                .then(function (response) {
                    $scope.files = response.data.data;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Files');
                });
        };
       
        $scope.loadIdeas = function () {
            var path = "notsecure/idea/retrieve.do?problemId=" + $scope.competitionId + "&page=1&start=0&limit=5";
            $http.get(path)
                .then(function (response) {
                    $scope.ideas = response.data.data;
                    $scope.ideaList = [];
                    $scope.ideaCount =  $scope.competition.userAcceptedIdeaCount + $scope.competition.userDeclinedCount + $scope.competition.userDraftCount + $scope.competition.userIdeaCount;
                }, function (response) {
                    //Second function handles error
                    Notification.error('Error: Ideas');
                });

        };
        $scope.isMine = function (id) {
            return false;
        };
        
        $scope.goSubmitIdea = function () {
            if ($rootScope.authenticated) {
                $location.path('/submit-idea/' + $scope.competition.id);
            } else {
                if ($scope.competition.publicMode == 2) {
                    $location.path('/submit-idea-external/' + $scope.competition.id);
                } else {
                    $translate('error.login.submit.idea').then(function (msg) {
                        $scope.sureSubmitIdea($scope);
                           
                        
                    });
                }
            }


        };

        $scope.sureSubmitIdea = function (t) {
            $rootScope.modalInstance = $uibModal.open({
                animation: true,backdrop: 'static',
                controller: function ($scope, $uibModalInstance) {
                    $scope.Ok = function () {
                        $rootScope.loginMsg = "error.login.submit.idea";
                        $rootScope.loginRedirect = "/submit-idea/" + t.competition.id;
                        $location.path('/login');
                        $uibModalInstance.close();
                    }
                    $scope.OkReg = function () {
                        $location.path('/register');
                        $uibModalInstance.close();
                    }
                    $scope.Close = function () {
                        $uibModalInstance.close();
                    }
                },
                ariaLabelledBy: "modal-title",
                ariaDescribedBy: "modal-body",
                templateUrl: "sureSubmitIdea.html"
            });
        };
            $scope.loadCompetition();
        
    }
]);
app.filter('fileTypes', function () {
    return function (input) {
        switch (input) {
            case "image/jpeg":
                return 'jpg.png';
            case "application/pdf":
                return 'pdf.png';

            default:
                return '_page.png';
        }
    };
});

app.filter('competitionState', function () {
    return function (input) {
        switch (input) {
            case "D":
                return 'competition.state.draft';
            case "S":
                return 'competition.state.submited';
            case "A":
                return 'competition.state.announced';
            case "E":
                return 'competition.state.evaluation';
            case "C":
                return 'competition.state.closed';
            case "T":
                return 'competition.state.terminated';
            case "P":
                return 'competition.state.pending.evaluation';
            case "K":
                return 'competition.state.pending.closure';
            default:
                return 'competition.state.unknown';
        }
    };
});
app.filter('ideaState', function () {
    return function (input) {
        switch (input) {
            case 9:
                return 'idea.state.draft';
            case 0:
                return 'idea.state.waiting';
            case 1:
                return 'idea.state.approved';
            case 2:
                return 'idea.state.denied';

            default:
                return 'idea.state.unknown';
        }
    };
});